import Api from "@/services/Index";

// Student
const start = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/turkmer/application/start", formData, {
        headers: {
            // remove headers
        },
    });
};

// Auth
const verify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/turkmer/application/verify", formData);
};

const login = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/turkmer/application/login", formData);
};

// Save
const save = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/turkmer/application/save", data);
};

const pinReminder = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/turkmer/application/pin-reminder", data);
};

export default {
    start,
    verify,
    login,
    save,
    pinReminder,
};
